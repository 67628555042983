//@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

$themeColor:white;

body{
  background-color:$themeColor;
}

.fourc-tile{
  padding:11px 0;
  text-align: center;
}

.fourc-tile:hover{
  /*background-color:#e1e1e1;
  cursor: pointer;*/
}

.fourc-clk{
  /*cursor: pointer;*/
}

.fourc-fo{
  font-style:italic;
}

.fourc-force-linebreak{
    -ms-word-break: break-all;
    word-break: break-all;

    /* Non standard for webkit */
    word-break: break-word;

    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
}

.fourc-nav-local{
  padding-bottom:2em;
}

.fourc-table-show{
  margin-top:1em;
}

/* bootstrap 3 dropped sub-menus - so let's pull it back in */


.dropdown-submenu {
  position:relative;
}
.dropdown-submenu>.dropdown-menu {
  top:0;
  left:100%;
  margin-top:-6px;
  margin-left:-1px;
  -webkit-border-radius:0 6px 6px 6px;
  -moz-border-radius:0 6px 6px 6px;
  border-radius:0 6px 6px 6px;
}
.dropdown-submenu:hover>.dropdown-menu {
  display:block;
}
.dropdown-submenu>a:after {
  display:block;
  content:" ";
  float:right;
  width:0;
  height:0;
  border-color:transparent;
  border-style:solid;
  border-width:5px 0 5px 5px;
  border-left-color:#cccccc;
  margin-top:5px;
  margin-right:-10px;
}
.dropdown-submenu:hover>a:after {
  border-left-color:#ffffff;
}
.dropdown-submenu.pull-left {
  float:none;
}
.dropdown-submenu.pull-left>.dropdown-menu {
  left:-100%;
  margin-left:10px;
  -webkit-border-radius:6px 0 6px 6px;
  -moz-border-radius:6px 0 6px 6px;
  border-radius:6px 0 6px 6px;
}


////////////////////////////////////////////////////////////////////////////////
/* http://stackoverflow.com/questions/24104072/stack-bootstrap-glyphicons
.glyphicon_round{
  font-size:3em;
  background-color: rgba(51, 102, 204, 1);
  padding: 0.5em;
  border-radius: 100%;
  color: #ffffff;
}

.glyphicon_round:hover{
  background-color: rgba(51, 102, 204, 0.5);
  color: #000000;
  cursor:pointer;
}

.fade-me{
  -webkit-transition: background-color 300ms linear;
  -moz-transition: background-color 300ms linear;
  -o-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  -webkit-transition: color 300ms linear;
  -moz-transition: color 300ms linear;
  -o-transition: color 300ms linear;
  -ms-transition: color 300ms linear;
}
*/

.glyphicon_round{
  font-size:3em;
  //background-color: rgba(51, 102, 204, 1);
  background-color: rgba(221, 97, 35, 1);
  padding: 0.5em;
  //border-radius: 100%;
  color: #ffffff;
}

.glyphicon_hide {
  color: rgba(51, 102, 204, 1);
}

.glyphicon_round_disabled{
  //background-color: rgba(51, 102, 204, 0.5) !important;
  background-color: rgba(221, 97, 35, 0.5) !important;
}



.glyphicon_round:hover{
  //background-color: rgba(51, 102, 204, 0.5);
  background-color: rgba(221, 97, 35, 0.5);
  color: #000000;
  cursor:pointer;
}

.glyphicon_round_disabled:hover{
  cursor:not-allowed;
}

.fade-me{
  -webkit-transition: background-color 300ms linear;
  -moz-transition: background-color 300ms linear;
  -o-transition: background-color 300ms linear;
  -ms-transition: background-color 300ms linear;
  -webkit-transition: color 300ms linear;
  -moz-transition: color 300ms linear;
  -o-transition: color 300ms linear;
  -ms-transition: color 300ms linear;
}
